var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"toc",class:{
    'pl-2': _vm.$vuetify.breakpoint.xs || _vm.$vuetify.breakpoint.xs,
    'pl-10':
      _vm.$vuetify.breakpoint.md ||
      _vm.$vuetify.breakpoint.lg ||
      _vm.$vuetify.breakpoint.xl,
    shaded: _vm.$vuetify.breakpoint.xs || _vm.$vuetify.breakpoint.xs,
  }},[(_vm.tocHeading.length)?_c('div',{ref:"anchor",staticClass:"mb-4 hover",staticStyle:{"margin-left":"-3px","font-weight":"bold"},on:{"click":function($event){return _vm.$vuetify.goTo(0)}}},[_vm._v(" "+_vm._s(_vm._f("upperCase")(_vm.tocHeading))+" ")]):_vm._e(),_c('div',{staticClass:"divider"},[_c('ul',{staticClass:"toc-list"},_vm._l((_vm.toc),function(item,index){return _c('li',{key:index,staticClass:"tocListItem pl-5",on:{"click":function($event){return _vm.scrollTo(item.id)}}},[_c('span',{staticClass:"tocItem",attrs:{"id":("scrollTo-" + (item.id))}},[_vm._v(_vm._s(item.text))])])}),0)])])}
var staticRenderFns = []

export { render, staticRenderFns }