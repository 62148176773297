var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app-bar',{staticClass:"mb-0",staticStyle:{"border-top":"1px solid #ccc !important"},attrs:{"flat":"","color":"white"}},[(
      _vm.$vuetify.breakpoint.md ||
      _vm.$vuetify.breakpoint.lg ||
      (_vm.$vuetify.breakpoint.xl && _vm.mobileTitle)
    )?_c('div',{staticStyle:{"font-size":"26px","font-weight":"900","text-transform":"uppercase"}},[_vm._v(" "+_vm._s(_vm.title)+" ")]):(
      _vm.$vuetify.breakpoint.sm || (_vm.$vuetify.breakpoint.xs && _vm.mobileTitle)
    )?_c('div',{staticStyle:{"font-size":"26px","font-weight":"900","text-transform":"uppercase"}},[_vm._v(" "+_vm._s(_vm.mobileTitle)+" ")]):_c('div',{staticStyle:{"font-size":"26px","font-weight":"900","text-transform":"uppercase"}},[_vm._v(" "+_vm._s(_vm.title)+" ")]),_c('v-spacer'),(_vm.menuItems && _vm.menuItems.length > 1)?_c('v-menu',{scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticStyle:{"margin-right":"0px !important","font-weight":"900"},attrs:{"text":"","large":""}},'v-btn',attrs,false),on),[_vm._v(" MENU "),_c('v-icon',{attrs:{"right":""}},[_vm._v("mdi-dots-vertical")])],1)]}}],null,false,11617544)},[_c('v-list',_vm._l((_vm.menuItems),function(item,idx){return _c('div',{key:("menu-" + idx)},[(item.type != 'external')?_c('v-list-item',{attrs:{"to":item.url}},[_c('v-list-item-title',{staticClass:"hover"},[_vm._v(_vm._s(item.label))])],1):_vm._e(),(item.type === 'external')?_c('v-list-item',{attrs:{"href":item.url}},[_c('v-list-item-title',{staticClass:"hover"},[_vm._v(_vm._s(item.label))])],1):_vm._e()],1)}),0)],1):_vm._e(),(_vm.menuItems && _vm.menuItems.length === 1)?_c('v-btn',{staticStyle:{"font-weight":"900"},attrs:{"large":"","text":"","to":_vm.menuItems[0].url}},[_vm._v(_vm._s(_vm.menuItems[0].label)+" »")]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }