var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{staticClass:"elevation-0 hover",staticStyle:{"border":"1px solid #eee","background":"#fff"},attrs:{"headers":_vm.showByDate ? _vm.policyHeadersFull : _vm.policyHeadersSimple,"items":_vm.items,"item-key":"title","search":_vm.search,"sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc,"footer-props":{
      'items-per-page-options': [100, 200, 300 - 1],
    },"items-per-page":100},on:{"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event},"update:sortDesc":function($event){_vm.sortDesc=$event},"update:sort-desc":function($event){_vm.sortDesc=$event}},scopedSlots:_vm._u([{key:"item.published_at",fn:function(ref){
    var item = ref.item;
return [_c('div',{staticStyle:{"width":"110px","font-size":"14px","font-weight":"400","color":"#555"},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.downloadFile(item.attachments[0])}}},[_c('span',{},[_vm._v(" "+_vm._s(_vm._f("dateFormatAlt")(item.published_at))+" ")])])]}},{key:"item.updated_at",fn:function(ref){
    var item = ref.item;
return [_c('div',{staticClass:"text-center",staticStyle:{"margin-left":"-5px"},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.downloadFile(item.attachments[0])}}},[_vm._v(" "+_vm._s(_vm.formatDate(item.updated_at))+" ")])]}},{key:"item.title",fn:function(ref){
    var item = ref.item;
return [_c('div',{staticStyle:{"font-size":"14px","font-weight":"700","color":"#333","padding-top":"5px","padding-bottom":"5px","text-transform":"uppercase"},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.downloadFile(item.attachments[0])}}},[_c('span',{},[_c('strong',[_vm._v(_vm._s(item.title))])])])]}},{key:"item.attachments[0].url",fn:function(ref){
    var item = ref.item;
return [_c('div',{staticClass:"text-center",staticStyle:{"font-size":"14px","font-weight":"700","color":"#888","margin-left":"-20px"},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.downloadFile(item.attachments[0])}}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c('v-btn',{attrs:{"x-small":""}},[_vm._v("Download Form "),_c('v-icon',_vm._g(_vm._b({attrs:{"right":"","color":"blue"}},'v-icon',attrs,false),on),[_vm._v("mdi mdi-download-circle-outline")])],1)]}}],null,true)},[_c('span',[_vm._v("Click to download form")])])],1)]}},{key:"expanded-item",fn:function(ref){
    var headers = ref.headers;
    var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[_c('RequiredFormCard',{key:item.id,staticClass:"mx-2 my-4",attrs:{"item":item}})],1)]}},{key:"top",fn:function(){return [_c('v-sheet',{staticClass:"px-5 py-5 markdown-body"},[(_vm.heading && _vm.heading.length)?_c('h2',{attrs:{"id":_vm.generateSlug(_vm.heading)}},[_vm._v(" "+_vm._s(_vm.heading)+" ")]):_vm._e(),(_vm.text && _vm.text.length)?_c('div',{staticClass:"px-2 py-4",staticStyle:{"font-size":"14px"}},[_vm._v(" "+_vm._s(_vm.text)+" ")]):_vm._e(),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }