var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-container',{staticClass:"px-0",staticStyle:{"margin-top":"-12px"},attrs:{"fill-height":"","fluid":""}},[_c('v-row',{attrs:{"no-gutters":""}},_vm._l((_vm.items),function(item,index){return _c('v-col',{key:("item-" + index),staticStyle:{"margin-top":"1px"},attrs:{"cols":"12","md":_vm.getRibbonSize}},[(item && item.splash)?_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-img',{staticClass:"hover",class:{ 'right-rule': index < _vm.items.length - 1 },attrs:{"src":("https://agency.icjia-api.cloud" + (item.splash.formats.small.url)),"lazy-src":("https://agency.icjia-api.cloud" + (item.splash.formats.thumbnail.url)),"aspect-ratio":"1.7","height":"450"},on:{"click":function($event){_vm.$router.push(item.fullPath).catch(function (err) {
                  _vm.$vuetify.goTo(0);
                })}}},[_c('v-container',{attrs:{"fill-height":"","fluid":""}},[_c('v-row',{attrs:{"align":"center","justify":"center"}},[_c('v-col',{staticClass:"text-left",staticStyle:{"margin":"0","padding":"0"}},[_c('div',{staticClass:"px-4 pt-4 pb-12",class:{ 'feature-background': !hover },staticStyle:{"color":"#fff","width":"100% !important","height":"250px"}},[_c('v-container',{attrs:{"fill-height":"","fluid":""}},[_c('v-row',{attrs:{"align":"center","justify":"center"}},[_c('v-col',[_c('v-chip',{staticClass:"mb-4",staticStyle:{"color":"#000","font-weight":"900"},attrs:{"small":"","color":"white"}},[_vm._v(" Feature ")]),_c('h2',{staticStyle:{"font-size":"22px","font-weight":"bold"}},[_vm._v(" "+_vm._s(item.title)+" ")])],1)],1)],1)],1)])],1)],1),_c('v-fade-transition',[(hover)?_c('v-overlay',{attrs:{"absolute":"","color":"blue darken-4","opacity":".7"}},[_c('v-btn',{attrs:{"to":item.fullPath}},[_vm._v("Read more »")])],1):_vm._e()],1)],1)]}}],null,true)}):_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var hover = ref.hover;
return [_c('v-img',{staticClass:"hover",class:{ 'right-rule': index < _vm.items.length - 1 },attrs:{"src":"https://agency.icjia-api.cloud/uploads/medium_government_generic_07d231af16.jpg","lazy-src":"https://agency.icjia-api.cloud/uploads/thumbnail_government_generic_07d231af16.jpg","aspect-ratio":"1.7","height":"450"},on:{"click":function($event){_vm.$router.push(item.fullPath).catch(function (err) {
                  _vm.$vuetify.goTo(0);
                })}}},[_c('v-container',{attrs:{"fill-height":"","fluid":""}},[_c('v-row',{attrs:{"align":"center","justify":"center"}},[_c('v-col',{staticClass:"text-left",staticStyle:{"margin":"0","padding":"0"}},[_c('div',{staticClass:"px-4 pt-4 pb-12",class:{ 'feature-background': !hover },staticStyle:{"color":"#fff","width":"100% !important","height":"250px"}},[_c('v-container',{attrs:{"fill-height":"","fluid":""}},[_c('v-row',{attrs:{"align":"center","justify":"center"}},[_c('v-col',[_c('v-chip',{staticClass:"mb-4",staticStyle:{"color":"#000","font-weight":"900"},attrs:{"small":"","color":"white"}},[_vm._v(" Feature ")]),_c('h2',{staticStyle:{"font-size":"22px","font-weight":"bold"}},[_vm._v(" "+_vm._s(item.title)+" ")])],1)],1)],1)],1)])],1)],1),_c('v-fade-transition',[(hover)?_c('v-overlay',{attrs:{"absolute":"","color":"blue darken-4","opacity":".7"}},[_c('v-btn',{attrs:{"to":item.fullPath}},[_vm._v("Read more »")])],1):_vm._e()],1)],1)]}}],null,true)})],1)}),1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }