var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.attachments)?_c('v-sheet',{staticClass:"py-2"},[(_vm.useSecondLevelHeading)?_c('div',[(_vm.label && _vm.label.length)?_c('h2',{attrs:{"id":"attachments"}},[_vm._v(_vm._s(_vm.label))]):_c('h2',{attrs:{"id":"attachments"}},[_vm._v("Attachments")])]):_vm._e(),(!_vm.useSecondLevelHeading)?_c('div',{staticClass:"px-0",staticStyle:{"font-weight":"900","border-bottom":"1px solid #ccc","padding-bottom":"8px","text-transform":"uppercase"}},[(_vm.label && _vm.label.length)?_c('span',[_vm._v(" "+_vm._s(_vm.label))]):_c('span',{},[_vm._v("Attachments")])]):_vm._e(),_c('div',{},[(!_vm.hideUpdated)?_c('v-data-table',{staticClass:"elevation-0",attrs:{"dense":"","headers":_vm.headers,"items":_vm.attachments,"hide-default-footer":"","items-per-page":-1,"sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc},on:{"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event},"update:sortDesc":function($event){_vm.sortDesc=$event},"update:sort-desc":function($event){_vm.sortDesc=$event}},scopedSlots:_vm._u([{key:"item.updated_at",fn:function(ref){
var item = ref.item;
return [_c('span',{staticStyle:{"width":"90px","font-size":"14px","font-weight":"700","color":"#555"}},[_vm._v(" "+_vm._s(_vm._f("dateFormatAlt")(item.updated_at))+"   ")])]}},{key:"item.size",fn:function(ref){
var item = ref.item;
return [_c('span',{staticStyle:{"font-size":"12px"}},[_vm._v(_vm._s(_vm.niceBytes(item.size)))])]}},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('span',{staticStyle:{"font-size":"14px","font-weight":"400","color":"#555"},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.routeTo(item.url)}}},[_c('span',{staticClass:"attachment"},[_vm._v(" "+_vm._s(item.name)+" ")])])]}}],null,false,3498961973)}):_vm._e(),(_vm.hideUpdated)?_c('v-data-table',{staticClass:"elevation-0",attrs:{"dense":"","headers":_vm.slimHeaders,"items":_vm.attachments,"hide-default-footer":"","items-per-page":-1,"sort-by":_vm.slimSortBy,"sort-desc":_vm.slimSortDesc},on:{"update:sortBy":function($event){_vm.slimSortBy=$event},"update:sort-by":function($event){_vm.slimSortBy=$event},"update:sortDesc":function($event){_vm.slimSortDesc=$event},"update:sort-desc":function($event){_vm.slimSortDesc=$event}},scopedSlots:_vm._u([{key:"item.size",fn:function(ref){
var item = ref.item;
return [_c('span',{staticStyle:{"font-size":"12px"}},[_vm._v(_vm._s(_vm.niceBytes(item.size)))])]}},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('span',{staticStyle:{"font-size":"14px","font-weight":"400","color":"#555"},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.routeTo(item.url)}}},[_c('span',{staticClass:"attachment"},[_vm._v(" "+_vm._s(item.name)+" ")])])]}}],null,false,2725308089)}):_vm._e()],1)]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }