<template>
  <div v-if="queryResults && queryResults.length" class="markdown-body">
    <h2 v-if="title" class="ml-4">{{ title }}</h2>
    <div class="ml-4" v-if="queryResults && queryResults.length > 2">
      <!-- <span style="font-size: 12px; font-weight: 900">SORT BY:</span> -->
      <v-btn-toggle v-model="toggle_sort" mandatory class="mb-4">
        <v-btn x-small elevation="1" class="button-weight"> Title </v-btn>
        <v-btn x-small elevation="1" class="button-weight"> Date </v-btn>
      </v-btn-toggle>
      &nbsp;&nbsp;
      <v-btn-toggle v-model="toggle_direction" mandatory class="mb-4">
        <v-btn x-small elevation="1" class="button-weight"> Ascending </v-btn>
        <v-btn x-small elevation="1" class="button-weight"> Descending </v-btn>
      </v-btn-toggle>
    </div>
    <div v-for="(result, index) in queryResults" :key="index" class="px-3 mt-6">
      <SearchCardAlt :item="result.item" :threshold="0.2"></SearchCardAlt>
    </div>
  </div>
</template>

<script>
/* eslint-disable no-unused-vars */
import { EventBus } from "@/event-bus";
import DOMPurify from "dompurify";
import Fuse from "fuse.js";
import _ from "lodash";

export default {
  data() {
    return {
      queryResults: null,
      fuse: this.$myApp.fuse,
      filteredQueryResults: null,
      toggle_sort: 1,
      toggle_direction: 1,
    };
  },
  watch: {
    toggle_sort(newValue) {
      if (this.toggle_sort === 0) {
        this.toggle_direction = 0;
      }
      if (this.toggle_sort === 1) {
        this.toggle_direction = 1;
      }
      this.sort();
    },
    toggle_direction(newValue) {
      this.sort();
    },
  },
  created() {
    this.fuse.options.threshold = this.threshold;
  },
  mounted() {
    this.instantSearch(this.query);
  },
  methods: {
    sort() {
      let direction;
      if (this.toggle_direction === 0) {
        direction = "asc";
      } else {
        direction = "desc";
      }
      switch (this.toggle_sort) {
        case 0:
          this.queryResults = _.orderBy(
            this.queryResults,
            ["item.title"],
            [direction]
          );
          break;
        case 1:
          this.queryResults = _.orderBy(
            this.queryResults,
            ["item.date", "item.end"],
            [direction, direction]
          );
          break;

        default:
          console.log("Default case -- not sorted");
      }
    },
    instantSearch(query) {
      if (!this.query.length) return;
      let queryResults = this.fuse.search(this.query);
      //console.log(queryResults);
      // queryResults = _.orderBy(
      //   queryResults,
      //   ["item.contentType", "item.date", "item.end"],
      //   ["asc", "desc", "desc"]
      // );

      // prevent duplicated item
      let filteredQueryResults = queryResults.filter((result) => {
        let currentPath = this.$route.fullPath;
        currentPath += currentPath.endsWith("/") ? "" : "/";
        let searchResultPath = result.item.fullPath;
        searchResultPath += searchResultPath.endsWith("/") ? "" : "/";
        if (currentPath !== searchResultPath) return result;
        //console.log(currentPath === searchResultPath);
      });
      this.queryResults = filteredQueryResults;
      this.sort();
    },
  },
  props: {
    threshold: {
      type: Number,
      default: 0.2,
    },
    query: {
      type: String,
      default: "",
    },
    title: {
      type: String,
      default: "",
    },
    hideBiography: {
      type: Boolean,
      default: true,
    },
  },
};
</script>

<style lang="scss" scoped></style>
