var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('WidgetBar',{attrs:{"title":"Upcoming Events","mobileTitle":"Upcoming","menuItems":_vm.eventMenuItems}}),(!_vm.loading)?_c('v-card',{staticStyle:{"margin-top":"-5px","margin-bottom":"18px"},attrs:{"elevation":"0"}},[(_vm.$vuetify.breakpoint.sm || _vm.$vuetify.breakpoint.xs)?_c('div',{staticStyle:{"height":"15px"}}):_vm._e(),_c('v-tabs',{attrs:{"show-arrows":"","vertical":_vm.$vuetify.breakpoint.md ||
        _vm.$vuetify.breakpoint.lg ||
        _vm.$vuetify.breakpoint.xl},model:{value:(_vm.eventModel),callback:function ($$v) {_vm.eventModel=$$v},expression:"eventModel"}},[_c('div',{staticStyle:{"height":"15px"}}),_c('v-tab',[_vm._v("Community")]),_c('v-tab',[_vm._v("Meetings ")]),_c('v-tab',[_vm._v("Training")]),_c('v-tab',[_vm._v("Funding")]),_c('v-tab-item',[(_vm.community.length > 0)?_c('v-container',{staticStyle:{"background":"#fff"},attrs:{"fluid":""}},[_c('v-row',{attrs:{"no-gutters":""}},_vm._l((_vm.community.length),function(n){return _c('v-col',{key:("community-" + n),staticClass:"rule-left flex-container",attrs:{"cols":"12","md":_vm.getCardWidth(_vm.community.length)}},[_c('HomeEventCard',{staticClass:"mr-1",class:{
                  'flex-item-3': n === 3,
                  'flex-item-2': n === 2,
                  'flex-item-1': n === 1,
                },attrs:{"event":_vm.community[n - 1],"tag":"community"}})],1)}),1)],1):_c('v-container',{attrs:{"fluid":""}},[_c('EmptyCard',{attrs:{"text":"No upcoming community events"}})],1)],1),_c('v-tab-item',[(_vm.meetings.length > 0)?_c('v-container',{staticStyle:{"background":"#fff"},attrs:{"fluid":""}},[_c('v-row',{attrs:{"no-gutters":""}},_vm._l((_vm.meetings.length),function(n){return _c('v-col',{key:("meeting-" + n),staticClass:"rule-left flex-container",attrs:{"cols":"12","md":_vm.getCardWidth(_vm.meetings.length)}},[_c('HomeEventCard',{staticClass:"mr-1",class:{
                  'flex-item-3': n === 3,
                  'flex-item-2': n === 2,
                  'flex-item-1': n === 1,
                },attrs:{"event":_vm.meetings[n - 1],"tag":"meeting"}})],1)}),1)],1):_c('v-container',{attrs:{"fluid":""}},[_c('EmptyCard',{attrs:{"text":"No upcoming meetings"}})],1)],1),_c('v-tab-item',[(_vm.training.length > 0)?_c('v-container',{staticStyle:{"background":"#fff"},attrs:{"fluid":""}},[_c('v-row',{attrs:{"no-gutters":""}},_vm._l((_vm.training.length),function(n){return _c('v-col',{key:("training-" + n),staticClass:"rule-left flex-container",attrs:{"cols":"12","md":_vm.getCardWidth(_vm.training.length)}},[_c('HomeEventCard',{staticClass:"mr-1",class:{
                  'flex-item-3': n === 3,
                  'flex-item-2': n === 2,
                  'flex-item-1': n === 1,
                },attrs:{"event":_vm.training[n - 1],"tag":"training"}})],1)}),1)],1):_c('v-container',{attrs:{"fluid":""}},[_c('EmptyCard',{attrs:{"text":"No upcoming training opportunities"}})],1)],1),_c('v-tab-item',[(_vm.funding.length > 0)?_c('v-container',{staticStyle:{"background":"#fff"},attrs:{"fluid":""}},[_c('v-row',{attrs:{"no-gutters":""}},_vm._l((_vm.funding.length),function(n){return _c('v-col',{key:("funding-" + n),staticClass:"rule-left flex-container",attrs:{"cols":"12","md":_vm.getCardWidth(_vm.funding.length)}},[_c('HomeEventCard',{staticClass:"mr-1",class:{
                  'flex-item-3': n === 3,
                  'flex-item-2': n === 2,
                  'flex-item-1': n === 1,
                },attrs:{"event":_vm.funding[n - 1],"tag":"funding"}})],1)}),1)],1):_c('v-container',{attrs:{"fluid":""}},[_c('EmptyCard',{attrs:{"text":"No upcoming funding opportunities"}})],1)],1)],1)],1):_c('v-card',[_c('Loader')],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }