import Vue from "vue";
import upperFirst from "lodash/upperFirst";
import camelCase from "lodash/camelCase";

//TODO: Fix for all directories and subdirectories
const requireComponent = require.context(".", false, /[\w-]+\.vue$/);

requireComponent.keys().forEach((fileName) => {
  const componentConfig = requireComponent(fileName);
  const componentName = upperFirst(
    camelCase(fileName.replace(/^\.\//, "").replace(/\.\w+$/, ""))
  );
  //console.log(componentName, componentConfig);
  Vue.component(componentName, componentConfig.default || componentConfig);
});
