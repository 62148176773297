var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"resize",rawName:"v-resize",value:(_vm.resize),expression:"resize"}],staticStyle:{"border-bottom":"1px solid #fff","z-index":"10 !important"},attrs:{"id":"contextBar"}},[_c('v-app-bar',{class:{
      'text-left':
        _vm.$vuetify.breakpoint.md ||
        _vm.$vuetify.breakpoint.lg ||
        _vm.$vuetify.breakpoint.xl,
      'text-center': _vm.$vuetify.breakpoint.sm || _vm.$vuetify.breakpoint.xs,
    },staticStyle:{"background":"#0a3a60","color":"#fff","font-size":"15px"},attrs:{"height":"35"}},[_c('span',[_c('span',{staticClass:"hover",staticStyle:{"font-weight":"700"},on:{"click":function($event){return _vm.$router.push('/')}}},[_vm._v("ICJIA  » ")]),_c('span',{staticClass:"hover hidden-sm-and-down",staticStyle:{"font-weight":"700"},on:{"click":function($event){return _vm.routeToPage(_vm.contextMenu[0].defaultPath)}}},[_vm._v(" "+_vm._s(_vm.contextMenu[0].label))]),_c('span',{staticClass:"hover hidden-md-and-up",staticStyle:{"font-weight":"700"},on:{"click":function($event){return _vm.routeToPage(_vm.contextMenu[0].defaultPath)}}},[_vm._v(" "+_vm._s(_vm.contextMenu[0].shortLabel))]),(_vm.contextTitle)?_c('span',{staticClass:"hidden-sm-and-down",staticStyle:{"font-weight":"300"}},[_vm._v(" » "+_vm._s(_vm._f("truncate")(_vm.contextTitle,8))+" ")]):_vm._e()]),_c('v-spacer'),(_vm.contextMenu[0].showTranslation)?_c('v-btn',{attrs:{"text":"","x-small":"","dark":"","aria-label":"Translate this site on Google"},on:{"click":function($event){return _vm.openTranslationModal()}}},[_c('v-icon',{attrs:{"x-small":"","left":_vm.$vuetify.breakpoint.md ||
          _vm.$vuetify.breakpoint.lg ||
          _vm.$vuetify.breakpoint.xl}},[_vm._v("fas fa-globe")]),_c('span',{staticClass:"hidden-sm-and-down"},[_vm._v("Translate this site")])],1):_vm._e()],1),_c('v-app-bar',{attrs:{"height":"35","scroll-threshold":"0","color":"#eee"}},[_c('v-tabs',{staticClass:"context px-3",attrs:{"show-arrows":"","centered":"","center-active":"","height":"35","optional":""},model:{value:(_vm.contextTab),callback:function ($$v) {_vm.contextTab=$$v},expression:"contextTab"}},[_c('v-tabs-slider',{attrs:{"color":"black"}}),_vm._l((_vm.contextMenu[0].items),function(item,index){return _c('v-tab',{key:index,staticStyle:{"background":"#eee !important"},on:{"click":function($event){item.path && item.path.length
            ? _vm.routeToPage(item.path)
            : _vm.fireEvent(item.event)}}},[_vm._v(" "+_vm._s(item.label)+" "),(item.icon)?_c('v-icon',{attrs:{"right":"","small":""}},[_vm._v(_vm._s(item.icon))]):_vm._e()],1)}),(_vm.more.length)?_c('v-menu',{attrs:{"bottom":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"align-self-center mr-4",attrs:{"text":""}},'v-btn',attrs,false),on),[_vm._v(" more "),_c('v-icon',{attrs:{"right":""}},[_vm._v(" mdi-menu-down ")])],1)]}}],null,false,1469872478)},[_c('v-list',{staticClass:"grey lighten-3"},_vm._l((_vm.more),function(item){return _c('v-list-item',{key:item},[_vm._v(" "+_vm._s(item)+" ")])}),1)],1):_vm._e()],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }