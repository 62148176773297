<template>
  <div>
    <v-card
      @click="$router.push(`/about/biographies/${item.slug}`)"
      elevation="1"
      class="mb-2 py-8 px-2"
      style="border: 1px solid #ddd"
      v-if="item"
      :color="color"
    >
      <div class="d-flex flex-no-wrap">
        <v-avatar
          class="ma-3 hidden-sm-and-down"
          size="125"
          tile
          v-if="item.headshot && item.headshot.url"
        >
          <v-img
            :src="`https://agency.icjia-api.cloud${item.headshot.formats.thumbnail.url}`"
            :lazy-src="`https://agency.icjia-api.cloud${item.headshot.formats.thumbnail.url}`"
          ></v-img>
        </v-avatar>
        <span>
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <span
                class="text-h5 author-name hover ml-3"
                @click.stop.prevent="search(item.fullName)"
                v-bind="attrs"
                v-on="on"
                >{{ item.fullName }}<span v-if="item.suffix">,&nbsp;</span
                >{{ item.suffix }}
              </span>
            </template>
            <span>Search ICJIA for {{ item.fullName }}</span>
          </v-tooltip>
          <span v-if="showLink">
            <span>&nbsp;|&nbsp;</span>
            <v-btn
              style="margin-bottom: 4px !important; margin-left: -3px"
              :to="`/about/biographies/${item.slug}`"
              text
              x-small
              aria-label="View Biography"
              ><v-icon>link</v-icon></v-btn
            >
          </span>

          <v-card-subtitle style="margin-top: -10px">
            <span
              style="font-weight: 700"
              class=""
              v-if="item && item.unit && item.unit.title"
              >{{ item.unit.title }}&nbsp;|&nbsp;</span
            >

            <span style="font-weight: 700; color: #444" v-if="item.title"
              >{{ item.title }}
              <!-- <span v-if="item && item.affiliation === 'board'"> | </span> -->
            </span>
            <!-- <span
              v-if="item && item.affiliation === 'board'"
              style="font-weight: 700; color: #444"
            >
              ICJIA Board
            </span> -->
            <!-- <span
              v-if="item && item.affiliation === 'staff'"
              style="font-weight: 700; color: #444"
            >
              ICJIA Staff
            </span> -->
          </v-card-subtitle>
          <v-card-text
            class="text-left"
            v-if="item.body && item.body.length"
            v-html="render(item.body)"
          ></v-card-text>
        </span>
      </div>
    </v-card>
  </div>
</template>

<script>
import { renderToHtml } from "@/services/Markdown";
import { EventBus } from "@/event-bus";
export default {
  mounted() {
    const els = document.getElementsByClassName("author-name");
    for (let i = 0, len = els.length; i < len; ++i) {
      els[i].removeAttribute("aria-expanded");
    }
  },
  methods: {
    search(name) {
      let opts = {
        query: name,
        type: "general",
      };
      EventBus.$emit("search", opts);
    },
    render(content) {
      return renderToHtml(content);
    },
  },
  props: {
    item: {
      type: Object,
      default: () => {},
    },
    showLink: {
      type: Boolean,
      default: true,
    },
    color: {
      type: String,
      default: "#fff",
    },
    showStaticSearch: {
      type: Boolean,
      default: false,
    },
    hideBiography: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style>
.unit-title {
  text-decoration: underline;
}
.unit-title:hover {
  text-decoration: none;
  cursor: pointer;
}
</style>
