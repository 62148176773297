const singles = [
  //   {
  //     path: "/adultredeploy/",
  //     redirect: () => {
  //       window.location.href = "https://icjia.illinois.gov/adultredeploy/";
  //       //   return '/redirecting' // not important since redirecting
  //     },
  //   },
  // {
  //   path: "/i2i/",
  //   name: "i2i",
  //   component: () =>
  //     import(/* webpackChunkName: "i2i" */ "@/views/Singles/i2i.vue"),
  // },
  // {
  //   path: "/i2i-dev/",
  //   redirect: { name: "i2i" },
  // },
  // {
  //   path: "/i2i-dev/",
  //   name: "i2iDev",
  //   component: () =>
  //     import(/* webpackChunkName: "i2i" */ "@/views/Singles/i2i-dev.vue"),
  // },
];

export { singles };
